















import { Component, Prop, Vue } from "vue-property-decorator";
import PrimaryButton from "@/components/general/PrimaryButton.vue";

@Component({
	components: { PrimaryButton },
})
export default class RegisterForm extends Vue {
	@Prop({ default: false }) readonly showCancel!: boolean;
	@Prop({ default: "Header" }) readonly header!: string;
	@Prop({ default: "<p>Default Content</p>" }) readonly content!: string;
	@Prop({
		default: () => {
			return;
		},
	})
	confirmAction!: (...args: unknown[]) => unknown;
	@Prop({
		default: () => {
			return;
		},
	})
	cancelAction!: (...args: unknown[]) => unknown;

	cancel(): void {
		if (typeof this.cancelAction === "function") this.cancelAction();
		this.$emit("closeModal");
	}
	confirm(): void {
		if (typeof this.confirmAction === "function") this.confirmAction();
		this.$emit("closeModal");
	}
}
