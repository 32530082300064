












import { Component, Prop, Vue } from "vue-property-decorator";

type Groups = { label: string; options: string[] }[];

@Component
export default class InputText extends Vue {
	@Prop() value!: string;
	@Prop({ required: false, type: String }) labelText: string | undefined;
	@Prop({ required: false, type: String }) name: string | undefined;
	@Prop({ required: false, type: String }) id: string | undefined;
	@Prop({ required: false, type: String }) labelPos: undefined | "t" | "b" | "l" | "r";
	@Prop({ required: true }) groups!: Groups;
	get layout(): string {
		switch (this.labelPos) {
			case "t":
				return "flex-col";
			case "b":
				return "flex-col-reverse";
			case "l":
				return "flex-row";
			case "r":
				return "flex-row-reverse";
			default:
				return "";
		}
	}
}
