




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PrimaryButton extends Vue {
	@Prop({ default: "default value" }) readonly text!: string;
	@Prop({ required: true, type: Function }) onClick!: (...args: any[]) => any;
}
